<template>
  <div class="alert alert-danger" v-if="validation.messages.length > 0">
    <p>{{ $t('job.create_edit.display_error') }}</p>
    <ul>
      <li v-for="(message, index) in validation.messages" :key="index">{{ message }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CreateDisplayError',
  props: {
    validation: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  ul, li {
    list-style-type: disc;
  }
</style>
