<template>
  <div>
    <create-display-error :validation="validation"/>

    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('job.create_edit.section_two.budget') }}</h3>
      <p class="d-flex align-items-center">{{ $t('job.create_edit.section_two.budget_description') }}</p>
      <div class="row">
        <div class="col mx-0">
          <div class="form-group">
            <label>{{ $t('job.create_edit.section_two.price_from') }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="price input-group-text text-gray-dark smaller">$</span>
              </div>
              <input min="1" max="500" step="1" type="number" class="form-control" :placeholder="$t('job.create_edit.section_two.price_from_placeholder')" required
                     v-model="jobData.price_from" v-bind:class="{ 'border-danger': !isValid('price_from') }"/>
            </div>
          </div>
        </div>
        <div class="col mx-0">
          <div class="form-group">
            <label>{{ $t('job.create_edit.section_two.price_to') }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="price input-group-text text-gray-dark smaller">$</span>
              </div>
              <input min="1" max="500" step="1" type="number" class="form-control" :placeholder="$t('job.create_edit.section_two.price_to_placeholder')" required
                     v-model="jobData.price_to" v-bind:class="{ 'border-danger': !isValid('price_to') }"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('job.create_edit.section_two.expired_date') }}</h3>
      <p class="d-flex align-items-center">{{ $t('job.create_edit.section_two.expired_date_description') }}</p>
      <div>
        <b-form-select v-model="jobData.expire_after" class="mb-3">
          <b-form-select-option value="7">{{ $t('job.create_edit.section_two.expired_date_7_days') }}</b-form-select-option>
          <b-form-select-option value="14">{{ $t('job.create_edit.section_two.expired_date_14_days') }}</b-form-select-option>
        </b-form-select>
      </div>
    </div>

    <div class="text-right pt-2 pb-2 d-flex flex-md-row-reverse flex-column" v-if="displayButtons">
      <button class="btn btn-primary" @click="onSubmit">{{ $t('job.create_edit.button.save') }}
        <b-spinner small label="Small Spinner" class="ml-2" v-if="saving"/>
      </button>
      <button class="btn btn-link text-dark" @click="$emit('job-create-edit-back')">{{ $t('job.create_edit.button.back') }}</button>
    </div>
  </div>
</template>

<script>
import CreateDisplayError from './CreateDisplayError'

export default {
  name: 'CreateEditSection2',
  components: { CreateDisplayError },
  props: {
    job: {
      type: Object,
      required: true
    },
    validationFailed: {
      type: Object,
      required: false,
      default () {
        return { messages: [], fields: [] }
      }
    },
    displayButtons: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    'validationFailed': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.saving = false
        this.validation = newValue
      }
    }
  },
  data () {
    return {
      validation: {
        messages: [],
        fields: []
      },
      jobData: {
        price_to: this.job.price_to,
        price_from: this.job.price_from,
        expire_after: this.job.expire_after === null ? 7 : this.job.expire_after
      },
      saving: false
    }
  },
  mounted () {
    this.$tracker.createJobSection(this.$route.name === 'job-edit' ? 'editJob2' : 'createJob2')
  },
  methods: {
    onSubmit () {
      this.validation = { messages: [], fields: [] }
      this.cleanData()

      if (this.jobData.price_from === null || this.jobData.price_from <= 0) {
        this.validation.fields.push('price_from')
        this.validation.messages.push(this.$t('job.create_edit.section_two.validation.price_from_min'))
      } else if (this.jobData.price_from > 500) {
        this.validation.fields.push('price_from')
        this.validation.messages.push(this.$t('job.create_edit.section_two.validation.price_from_max'))
      }

      if (this.jobData.price_to === null || this.jobData.price_to <= 0) {
        this.validation.fields.push('price_to')
        this.validation.messages.push(this.$t('job.create_edit.section_two.validation.price_to_min'))
      } else if (this.jobData.price_to > 500) {
        this.validation.fields.push('price_to')
        this.validation.messages.push(this.$t('job.create_edit.section_two.validation.price_to_max'))
      }

      if (this.jobData.price_from > this.jobData.price_to) {
        this.validation.fields.push('price_from')
        this.validation.messages.push(this.$t('job.create_edit.section_two.validation.price_from_higher'))
      }

      if (this.validation.messages.length < 1) {
        this.$emit('job-create-edit-save', this.jobData)
        this.saving = true
      } else {
        window.scrollTo(0, 0)
      }
    },
    cleanData () {
      if (this.jobData.price_to !== null) {
        this.jobData.price_to = parseFloat(this.jobData.price_to)
      }
      if (this.jobData.price_from !== null) {
        this.jobData.price_from = parseFloat(this.jobData.price_from)
      }
    },
    isValid (value) {
      if (this.validation.messages.length > 0) {
        return this.validation.fields.indexOf(value) === -1
      }
      return true
    }
  }
}
</script>
